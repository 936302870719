import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Motion_Detection/INSTAR_Cloud/Storage/YoutubeVideoCardCloud';
import ConfigTable from 'components/Motion_Detection/INSTAR_Cloud/Storage/ConfigTable';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud - Add Storage",
  "path": "/Motion_Detection/INSTAR_Cloud/Storage/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Cloud Storage for Motion Detection Recordings",
  "image": "./MD_SearchThumb_Cloud_Storage.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Storage.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='INSTAR Cloud - Add Storage' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR Cloud Storage for Motion Detection Recordings' image='/images/Search/MD_SearchThumb_Cloud_Storage.png' twitter='/images/Search/MD_SearchThumb_Cloud_Storage.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/Speicherplatz/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/Storage/' crumbLabel="Add Storage" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h2>
    <h3 {...{
      "id": "storage-for-motion-detection-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#storage-for-motion-detection-recordings",
        "aria-label": "storage for motion detection recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Storage for Motion Detection Recordings`}</h3>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Each newly created cloud account is given a standard 10GB of storage for 30 days to test the Cloud extensively. If you like the INSTAR Cloud, we would be happy, of course, if you expand your storage space accordingly. You can easily do this by voucher codes, which you can purchase directly from the INSTAR website.`}</p>
    <p>{`See: `}<OutboundLink to="https://www.instar.de/cloud-ip-kamera-cam-sicherheitskamera-nvr-recorder.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`INSTAR Website`}</OutboundLink></p>
    <h2 {...{
      "id": "book-more-storage-space",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#book-more-storage-space",
        "aria-label": "book more storage space permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Book more storage space`}</h2>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`Log in with your cloud account and go to `}<strong parentName="p">{`Administration - Memory Plan`}</strong>{`. Here you see your current storage space as well as the runtime for which this is valid.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/675262fdf5c7263d0f72fa92519555c5/b8765/Cloud_Get_More_Cloudstorage01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACC0lEQVQ4y3WTS2/UMBCA8///BlKvqEdOXBCUAyBo2YVu2zw3u0mcp5M0ie18KN5Hq4q1NJqH7S/jmYnz/dcPdvuYKArZ3N/jPj3h+z5hEJx1EAQH7ftsHh/xgwDPD/A8j9u73/y8W7Far1mt1jhXV+/w3AeiwCMMAwvexxH7eHvUIbvtUeKIxyDkKYzwogg/jHA9lz9PHhvX5cF1cWRdEscxSbInkT1p01HkGSLdIbIEIQRlkVMVOVmaUIiMqhCUubDa+nlGXRaIdI9TyoFaZMg8BQyHpWAqQLfW00oxDAN93zNNk43Nx5NLbDzGmrrG2d9e82Ed8H6142Mkuck1X4TmJp/5LDRfc8OulIg0QUqJUuoAnGcrR+cIrHCy+C+bbcKdv8MTNWKcyZ41aT8hBoMYZkZt0FrZ7IwxZ+BbsAWKSlLlgudWwmzQ4wB6oq0rLq0T5LW8ZJjsydKUOs8saHnSNClbl0kptDH/BVwEbrOCrSj5FuWs0gZRS7qus9K27UsT3lx+a5+Bbd/TDyPXfsunZLj4xEv+a9sCT3/AcyXQvaSua8ZxtKNSluW5q8t4FEVxzrhpGqrqUOe2bxmnEdnUOFpre3g+TqG159l2c7m0wE/AtuvOGS3lqJvG2nJsmWZF2zQ4y2zNxtgvKzVhtLZZLbJAl3FR0yFuzGlvsrbdV4q+66jKiqos+QeoV+Yu+WyLkgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/675262fdf5c7263d0f72fa92519555c5/e4706/Cloud_Get_More_Cloudstorage01.avif 230w", "/en/static/675262fdf5c7263d0f72fa92519555c5/d1af7/Cloud_Get_More_Cloudstorage01.avif 460w", "/en/static/675262fdf5c7263d0f72fa92519555c5/7f308/Cloud_Get_More_Cloudstorage01.avif 920w", "/en/static/675262fdf5c7263d0f72fa92519555c5/e1c99/Cloud_Get_More_Cloudstorage01.avif 1380w", "/en/static/675262fdf5c7263d0f72fa92519555c5/76ea5/Cloud_Get_More_Cloudstorage01.avif 1840w", "/en/static/675262fdf5c7263d0f72fa92519555c5/b2242/Cloud_Get_More_Cloudstorage01.avif 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/675262fdf5c7263d0f72fa92519555c5/a0b58/Cloud_Get_More_Cloudstorage01.webp 230w", "/en/static/675262fdf5c7263d0f72fa92519555c5/bc10c/Cloud_Get_More_Cloudstorage01.webp 460w", "/en/static/675262fdf5c7263d0f72fa92519555c5/966d8/Cloud_Get_More_Cloudstorage01.webp 920w", "/en/static/675262fdf5c7263d0f72fa92519555c5/445df/Cloud_Get_More_Cloudstorage01.webp 1380w", "/en/static/675262fdf5c7263d0f72fa92519555c5/78de1/Cloud_Get_More_Cloudstorage01.webp 1840w", "/en/static/675262fdf5c7263d0f72fa92519555c5/7208c/Cloud_Get_More_Cloudstorage01.webp 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/675262fdf5c7263d0f72fa92519555c5/81c8e/Cloud_Get_More_Cloudstorage01.png 230w", "/en/static/675262fdf5c7263d0f72fa92519555c5/08a84/Cloud_Get_More_Cloudstorage01.png 460w", "/en/static/675262fdf5c7263d0f72fa92519555c5/c0255/Cloud_Get_More_Cloudstorage01.png 920w", "/en/static/675262fdf5c7263d0f72fa92519555c5/b1001/Cloud_Get_More_Cloudstorage01.png 1380w", "/en/static/675262fdf5c7263d0f72fa92519555c5/161ec/Cloud_Get_More_Cloudstorage01.png 1840w", "/en/static/675262fdf5c7263d0f72fa92519555c5/b8765/Cloud_Get_More_Cloudstorage01.png 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/675262fdf5c7263d0f72fa92519555c5/c0255/Cloud_Get_More_Cloudstorage01.png",
              "alt": "INSTAR Cloud Storage Introduction",
              "title": "INSTAR Cloud Storage Introduction",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`To increase or decrease your disk space, first purchase disk space from the INSTAR web page. The storage space is always in units of 10GB. Should you, e.g. 20GB, then you can add 10GB to your account in the first step and in the second step you can use the second 10GB either to extend your runtime or to increase the memory space to 20GB. You can choose this freely. Copy the voucher code for your store that you receive by email after purchase and add the voucher code in your cloud account and then choose `}<strong parentName="p">{`Check Voucher`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/09802d796ae2669c107f7b6fa59861d9/b8765/Cloud_Get_More_Cloudstorage02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACE0lEQVQ4y3WTyXLTQBCG9f6vQRUHTnDkwoEDBA4UECcOcRKttrXMaLRby0gfNeM4CVQ8VV3q7un5++9Fzo/Ln8TJjigK2dze4j484PseQRDg+z5hEFj9ZG/u7vGDAM/38TyPy9UVv1bXXK/XXF2vcd6+fYPv3RMFHmEYWOB4FxHvto/fiP02PMou4j4IccMIL4rwwxDXc7l58Ni4Lneui1OXit1uS5LEJHVHWrVIkSHSPSJLECJD5QKVS7I0JhcphbHl0Z+LjEJmlCpHpAmOqntKmVHLFJg5ngnGHHRjLT1N9H1P13WM42h9y2Nk23UMj76yLHDi3x/4uA54v97zeddxITVfheZCLnwRmm/5zF7VZGlCXddM03QEXBYrJ90CFgVOtv3DZpuw8vd4skIMC9lBk3Yjop8R/cKgZ8vSsJvn+R+Ql8CWoVA1hRR0TQ2zRg896JG2LDh3TiAv5RkwjRFZSiWFaZYtaRwn25dxmtDz/CrAWUA3lniJ5LufsooLRFnTtq2Vpmmeh/Df41d7aBmWNbJqeXeT8SmszpZ4zn6p26FEYWD/hqHMmQ8NZVkyDIMtXSn1NFWzMjLPnxibOCPm1F3DMA5UZYmjtbbBJs+8wOFwsFmN36yJAT8BmjacGJl2VNWxompomBZNXVU45pFZBZN5MmuhtWVlxPi1GdQ42gTzfLobrW7ujd21LYVSKJXzFwpl5byUDd74AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09802d796ae2669c107f7b6fa59861d9/e4706/Cloud_Get_More_Cloudstorage02.avif 230w", "/en/static/09802d796ae2669c107f7b6fa59861d9/d1af7/Cloud_Get_More_Cloudstorage02.avif 460w", "/en/static/09802d796ae2669c107f7b6fa59861d9/7f308/Cloud_Get_More_Cloudstorage02.avif 920w", "/en/static/09802d796ae2669c107f7b6fa59861d9/e1c99/Cloud_Get_More_Cloudstorage02.avif 1380w", "/en/static/09802d796ae2669c107f7b6fa59861d9/76ea5/Cloud_Get_More_Cloudstorage02.avif 1840w", "/en/static/09802d796ae2669c107f7b6fa59861d9/b2242/Cloud_Get_More_Cloudstorage02.avif 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/09802d796ae2669c107f7b6fa59861d9/a0b58/Cloud_Get_More_Cloudstorage02.webp 230w", "/en/static/09802d796ae2669c107f7b6fa59861d9/bc10c/Cloud_Get_More_Cloudstorage02.webp 460w", "/en/static/09802d796ae2669c107f7b6fa59861d9/966d8/Cloud_Get_More_Cloudstorage02.webp 920w", "/en/static/09802d796ae2669c107f7b6fa59861d9/445df/Cloud_Get_More_Cloudstorage02.webp 1380w", "/en/static/09802d796ae2669c107f7b6fa59861d9/78de1/Cloud_Get_More_Cloudstorage02.webp 1840w", "/en/static/09802d796ae2669c107f7b6fa59861d9/7208c/Cloud_Get_More_Cloudstorage02.webp 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09802d796ae2669c107f7b6fa59861d9/81c8e/Cloud_Get_More_Cloudstorage02.png 230w", "/en/static/09802d796ae2669c107f7b6fa59861d9/08a84/Cloud_Get_More_Cloudstorage02.png 460w", "/en/static/09802d796ae2669c107f7b6fa59861d9/c0255/Cloud_Get_More_Cloudstorage02.png 920w", "/en/static/09802d796ae2669c107f7b6fa59861d9/b1001/Cloud_Get_More_Cloudstorage02.png 1380w", "/en/static/09802d796ae2669c107f7b6fa59861d9/161ec/Cloud_Get_More_Cloudstorage02.png 1840w", "/en/static/09802d796ae2669c107f7b6fa59861d9/b8765/Cloud_Get_More_Cloudstorage02.png 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/09802d796ae2669c107f7b6fa59861d9/c0255/Cloud_Get_More_Cloudstorage02.png",
              "alt": "INSTAR Cloud Storage Introduction",
              "title": "INSTAR Cloud Storage Introduction",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`If the voucher code is valid, your account is now expanded to 10GB. A 10GB unit always has a running time of 365 days which you in your account then also indicated accordingly.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/fd561/Cloud_Get_More_Cloudstorage03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACEUlEQVQ4y4WSW2/TQBBG/f9/BRJPPPKKBDyh0kqobUITkdiJd+31PXF8SWLHl1N5naQVFBjp0+zMzpzdWa1xP3kgCHyU47JcLFlbK8ylib22kULgCIkUcvS2wDQthHCwbalrptMnHqczZrO5lvH+wzssZ4FQFraykP4a17dRgcCLJF4oRx9JVChYehLLd1gHDnYgMR2LmVzzy1mxtFcYE++eu9UNd9YNt+Y3Js4PnrxHfqqHPzTkzXiGmcxGr9dzVskcczNHhhaGEGuktFGuRG1SVLIh8BW+7+EHHr6vCAJFqOWdpa4+Cl9yvuditHXFPks5Fhn0Pdq6Gg4J1PkQ0LUNzamhKAqqqqLvetq2pe97yqLUucGydItxazp8N11uFpLl9oBz6BFliyxqRFHjHiHeZSRJzPF45NQ0urlnPHwAd103Ancpxpely+eFz6e5w62XM806Jmk7atcx3XWkh5pDWeobnk6nEdj3Wpf1FVjsthTbRI9N20B70qrKnL/ZBfA7WAPLsmRQcz55GKHtOupz3PUvTf/SFWgKyUq6TC2Bu8nYFSX7/V7rrRH/CyyONWXd8NGt+OpXVE073vKsy4O/Ne41fg2MopAoDInimDzP6c7f4TVsiJum0brcaFhf9ofawfIsxXAclyiKdMHwn5IkGTfznDiOddPQoJTSzzDAhpzruqRpqmvDbYQfBxR5zjPaGNxSax3EEwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77dd6ff9f9e055ba29e1de2589b341ab/e4706/Cloud_Get_More_Cloudstorage03.avif 230w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/d1af7/Cloud_Get_More_Cloudstorage03.avif 460w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/7f308/Cloud_Get_More_Cloudstorage03.avif 920w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/e1c99/Cloud_Get_More_Cloudstorage03.avif 1380w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/76ea5/Cloud_Get_More_Cloudstorage03.avif 1840w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/2ad91/Cloud_Get_More_Cloudstorage03.avif 2866w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/77dd6ff9f9e055ba29e1de2589b341ab/a0b58/Cloud_Get_More_Cloudstorage03.webp 230w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/bc10c/Cloud_Get_More_Cloudstorage03.webp 460w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/966d8/Cloud_Get_More_Cloudstorage03.webp 920w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/445df/Cloud_Get_More_Cloudstorage03.webp 1380w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/78de1/Cloud_Get_More_Cloudstorage03.webp 1840w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/aa276/Cloud_Get_More_Cloudstorage03.webp 2866w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77dd6ff9f9e055ba29e1de2589b341ab/81c8e/Cloud_Get_More_Cloudstorage03.png 230w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/08a84/Cloud_Get_More_Cloudstorage03.png 460w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/c0255/Cloud_Get_More_Cloudstorage03.png 920w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/b1001/Cloud_Get_More_Cloudstorage03.png 1380w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/161ec/Cloud_Get_More_Cloudstorage03.png 1840w", "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/fd561/Cloud_Get_More_Cloudstorage03.png 2866w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/77dd6ff9f9e055ba29e1de2589b341ab/c0255/Cloud_Get_More_Cloudstorage03.png",
              "alt": "INSTAR Cloud Storage Introduction",
              "title": "INSTAR Cloud Storage Introduction",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4",
        "aria-label": "step 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4`}</h3>
    <p>{`If you decide to add a second 10GB unit to your account, you can choose whether you want to extend the storage space or extend the runtime as mentioned above. In our example, we expand the memory space once.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e1e550a6495e69106175705a608d1ce2/60708/Cloud_Get_More_Cloudstorage04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB0ElEQVQ4y42TSW/UQBCF/f//CxeWA0gIxAFFcCOHmWQIM+NZbLf3Xrx02/Mh9wSTRBNISU9dJb16XaolSMuMoiqIheDXesNut2Oz2XA4HIiiyON4PM5vuA1nf+Isljcsbm5ZrVYegRAJaSqQuYDBMgwDzjn/juPI/2ziunuukpIgTFLCJOP9Xca3Y00hNcYYD6011lqfeDqdLuKhNcYQ1EpT6oYvcct1YXHjY/JzQpeEjdEEIo4JV0vy7R1tnqCKlDzPPZIkoSzLc8JLBde54vVtyuej4eNec5UNFEpTVyVd189V/sseV5gXfL9esFyHREVNVErKSlJVNVpKbN/9TXpZhZJXP7Z8+Cn4FFZ8jTRZFpGnMYVp0XZguDCEp32eBU3vEI0jUR1pO1J3DafNG0R4xbto5O3BknfjRaGn7fCCu3BLtN9R5RlG1jRKwmmgaQxSa8YTfseqqqJt21nkYdx2Lb3tfU4wTXN/OMxLOhEnm6a73W5wznrB6YL+THzCFMdJ4rmxEqRNTt90BOM40Pc9zlqfPExXMm2/s36p/SU45/2+7+Z44lvb31+V858YrQniOMJohZK1Px2l5NlXEu39c6yfi5VEJDH7XUgqEn4DkJXqyow0Gk0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1e550a6495e69106175705a608d1ce2/e4706/Cloud_Get_More_Cloudstorage04.avif 230w", "/en/static/e1e550a6495e69106175705a608d1ce2/d1af7/Cloud_Get_More_Cloudstorage04.avif 460w", "/en/static/e1e550a6495e69106175705a608d1ce2/7f308/Cloud_Get_More_Cloudstorage04.avif 920w", "/en/static/e1e550a6495e69106175705a608d1ce2/e1c99/Cloud_Get_More_Cloudstorage04.avif 1380w", "/en/static/e1e550a6495e69106175705a608d1ce2/76ea5/Cloud_Get_More_Cloudstorage04.avif 1840w", "/en/static/e1e550a6495e69106175705a608d1ce2/31d6f/Cloud_Get_More_Cloudstorage04.avif 2872w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e1e550a6495e69106175705a608d1ce2/a0b58/Cloud_Get_More_Cloudstorage04.webp 230w", "/en/static/e1e550a6495e69106175705a608d1ce2/bc10c/Cloud_Get_More_Cloudstorage04.webp 460w", "/en/static/e1e550a6495e69106175705a608d1ce2/966d8/Cloud_Get_More_Cloudstorage04.webp 920w", "/en/static/e1e550a6495e69106175705a608d1ce2/445df/Cloud_Get_More_Cloudstorage04.webp 1380w", "/en/static/e1e550a6495e69106175705a608d1ce2/78de1/Cloud_Get_More_Cloudstorage04.webp 1840w", "/en/static/e1e550a6495e69106175705a608d1ce2/c4a82/Cloud_Get_More_Cloudstorage04.webp 2872w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1e550a6495e69106175705a608d1ce2/81c8e/Cloud_Get_More_Cloudstorage04.png 230w", "/en/static/e1e550a6495e69106175705a608d1ce2/08a84/Cloud_Get_More_Cloudstorage04.png 460w", "/en/static/e1e550a6495e69106175705a608d1ce2/c0255/Cloud_Get_More_Cloudstorage04.png 920w", "/en/static/e1e550a6495e69106175705a608d1ce2/b1001/Cloud_Get_More_Cloudstorage04.png 1380w", "/en/static/e1e550a6495e69106175705a608d1ce2/161ec/Cloud_Get_More_Cloudstorage04.png 1840w", "/en/static/e1e550a6495e69106175705a608d1ce2/60708/Cloud_Get_More_Cloudstorage04.png 2872w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e1e550a6495e69106175705a608d1ce2/c0255/Cloud_Get_More_Cloudstorage04.png",
              "alt": "INSTAR Cloud Storage Introduction",
              "title": "INSTAR Cloud Storage Introduction",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-5",
        "aria-label": "step 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 5`}</h3>
    <p>{`Under the item `}<em parentName="p">{`Warn with memory space used by:`}</em>{`, you can set the time at which you wish to receive a warning. For example, have added several cameras in the cloud and the storage space is almost full, then you can set from what time you want to receive an email with the note that either videos must be deleted or the space must be expanded. You also have the option to activate the automatic deletion under `}<strong parentName="p">{`Administration alarms`}</strong>{`. This is explained in `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/INSTAR_Cloud/Administration/"
      }}>{`Administration`}</a>{` and overview of possible settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/462853aed6fc0e426e4571847ae5df5d/b8765/Cloud_Get_More_Cloudstorage05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACHUlEQVQ4y4WS2W7bMBBF9f+/UaBPBfrelyAImgItgizeGlsrtVuyrd2StZxCtOU2QdEOcDGcS87lcDjKw/MjYRjgOg7qeoOhG2gbFcswsS2BLeyrF5ZAVXUsy8Y0BaZhMZsteJ4tWS5XEsrHTx/Q7DWmq2I4KsLTsX0DJzBxQ+sNRm7jWWi+QA8ERiDQHI2lMHh1dDaWjvLiPfJd+3aG+pUX+4GF98zcfXqDmfskeTVaoUbLK7R4hR6vUHcrRKihCMvEFhaea+PtE7zdnjDwCAKPMPCl344I/StCuXeG5IIzF3guSt/UFMmBY57BMCCtb6CKocnGgL5raduWPM+p65phGOi6TvqiKCQ3WpokKPdrwf3G4e7V4nVXYZYDet5h5Cf07IRVQXRIiaItVVVxOp1k8nC5vOt7+r6X6yxNUW7WDjcbny9LwQ8/42cxsMo65knHPO1ZpD3psaEqC/KieCM4iU5eCubJnmwfc8xT2qYmjFPipCZLkvPzubThD5sE3gtLwbIsKcqSoWtpmhN3c5/bRUKcVud2Dr+T/oWr4MYUaJbNTLNwdilZntMcC6qylJ/w/on/FSyONXnd8lnU3PpHmran7Tq6rpc/OTX8b8+9xhdKCm7DEIkoktV1XXsdixFT0ljpODpTRWM8XTady7IUxXEc4jiW5DhPcby7bGZEcSyTxj3P8xj7PYqNnOu6HA4HeXZ7iPCjgDzL+AU9jts9UHtL8AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/462853aed6fc0e426e4571847ae5df5d/e4706/Cloud_Get_More_Cloudstorage05.avif 230w", "/en/static/462853aed6fc0e426e4571847ae5df5d/d1af7/Cloud_Get_More_Cloudstorage05.avif 460w", "/en/static/462853aed6fc0e426e4571847ae5df5d/7f308/Cloud_Get_More_Cloudstorage05.avif 920w", "/en/static/462853aed6fc0e426e4571847ae5df5d/e1c99/Cloud_Get_More_Cloudstorage05.avif 1380w", "/en/static/462853aed6fc0e426e4571847ae5df5d/76ea5/Cloud_Get_More_Cloudstorage05.avif 1840w", "/en/static/462853aed6fc0e426e4571847ae5df5d/b2242/Cloud_Get_More_Cloudstorage05.avif 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/462853aed6fc0e426e4571847ae5df5d/a0b58/Cloud_Get_More_Cloudstorage05.webp 230w", "/en/static/462853aed6fc0e426e4571847ae5df5d/bc10c/Cloud_Get_More_Cloudstorage05.webp 460w", "/en/static/462853aed6fc0e426e4571847ae5df5d/966d8/Cloud_Get_More_Cloudstorage05.webp 920w", "/en/static/462853aed6fc0e426e4571847ae5df5d/445df/Cloud_Get_More_Cloudstorage05.webp 1380w", "/en/static/462853aed6fc0e426e4571847ae5df5d/78de1/Cloud_Get_More_Cloudstorage05.webp 1840w", "/en/static/462853aed6fc0e426e4571847ae5df5d/7208c/Cloud_Get_More_Cloudstorage05.webp 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/462853aed6fc0e426e4571847ae5df5d/81c8e/Cloud_Get_More_Cloudstorage05.png 230w", "/en/static/462853aed6fc0e426e4571847ae5df5d/08a84/Cloud_Get_More_Cloudstorage05.png 460w", "/en/static/462853aed6fc0e426e4571847ae5df5d/c0255/Cloud_Get_More_Cloudstorage05.png 920w", "/en/static/462853aed6fc0e426e4571847ae5df5d/b1001/Cloud_Get_More_Cloudstorage05.png 1380w", "/en/static/462853aed6fc0e426e4571847ae5df5d/161ec/Cloud_Get_More_Cloudstorage05.png 1840w", "/en/static/462853aed6fc0e426e4571847ae5df5d/b8765/Cloud_Get_More_Cloudstorage05.png 2868w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/462853aed6fc0e426e4571847ae5df5d/c0255/Cloud_Get_More_Cloudstorage05.png",
              "alt": "INSTAR Cloud Storage Introduction",
              "title": "INSTAR Cloud Storage Introduction",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "general-info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#general-info",
        "aria-label": "general info permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`General Info`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You only have to look in the INSTAR Cloud that you have enough space available. How many cameras you add to your cloud account is and does not matter. For example, you can use a 10GB unit. also easily with 4 cameras use. Of course, this depends on how many alarms take place and how long you want to save the recordings.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The size of your alarm video depends on some factors. However, in order to keep the size low without major loss of quality, we recommend setting the following values directly under _`}<em parentName="p">{`Multimedia video`}</em>{` in your camera (via the web interface of your camera):`}</p>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConfigTable mdxType="ConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      